import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useContainerStyles = makeStyles((theme: Theme) => 
({
    container: {
        padding: 8,

        [theme.breakpoints.up("md")]:
        {
            padding: 16,
        }
    },
}));