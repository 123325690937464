import { ItemDetails, Loader } from 'components';
import { useApi, useSearch } from 'hooks';
import { ItemDetailsModel } from 'models';
import React, { useEffect, useState } from 'react';

export const ItemPage: React.FC = () =>
{
	const [loading, setLoading] = useState(false);
	const [item, setItem] = useState<ItemDetailsModel>();
	const search = useSearch();
	const getItem = useApi.getItem(search.parameters.itemId);

	const onFetch = () => 
	{
		if (!getItem)
			return;
		
		setLoading(true);
		getItem()
			.then((res) =>
			{
				setItem(res.data);
			})
			.finally(() => 
			{
				setLoading(false);
			});
	};

	useEffect(onFetch, [setItem])

	if (loading || !item)
	{
		return <Loader />;
	}

	return (
		<ItemDetails data={item} />
	);
}
