import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useTextItemShortStyles = makeStyles((theme: Theme) => ({
    itemShort__column1: {
        flexGrow: 1,
    },
    itemShort__column2: {

    },
    itemShort__button: {
        width: "100%",
        display: "block",
    },
    itemShort__nameContainer: {
        display: "flex",
        alignItems: "center",
    },
    itemShort__name: {
        fontSize: "1.25rem",
        textAlign: "left",
    },
    itemShort__extractContainer: {
        maxWidth: 500,
    },
    itemShort__extract: {

    },
    itemShort__date: {
        display: "inline-block",
        textTransform: "capitalize",
        paddingLeft: theme.spacing(1),
        color: theme.palette.grey[500],
        fontSize: "0.8rem",
    },
    itemShort__metaContainer: {
        display: "flex",
        margin: -theme.spacing(0.5),
        color: theme.palette.grey[500],
        alignItems: "center",

        "& > *": {
            margin: theme.spacing(0.5),

            "& + *::before": {
                content: "' - '",
            },
        },
    },
    itemShort__metaIcon: {
        height: 18,
        width: 18,
    }
}));