import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useAccountMenuStyles = makeStyles((theme: Theme) =>
    ({
        accountMenu__loginFields: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2),
            margin: -theme.spacing(2),

            "& > *": {
                margin: theme.spacing(2)
            }
        },
    }));