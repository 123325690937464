import { ItemDetailsModel } from "models";
import axios from "axios";

export const useApi_getItem = (providerId?: string, itemId?: string) => 
{
    if (!providerId || !itemId)
        return;

    const get = () =>
    {
        return axios.get<ItemDetailsModel>(`item/details/${providerId}/${itemId}`);
    };

    return get;
}