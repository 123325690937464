import { IntlProvider } from 'react-intl';
import React, { FC, createContext, useState } from "react";
import messagesEn from "../locales/en.json";
import messagesDa from "../locales/da.json";
import messages from "../locales/locales.json";

export interface IntlContextProps
{

}

export interface IntlContextState 
{
    setLanguage: (value: string) => void;
}

export const IntlContext = createContext<IntlContextState>({
    setLanguage: () => { },
});

export const IntlContextProvider: FC<IntlContextProps> = (props) =>
{
    const [locale, setLocale] = useState(localStorage.getItem('locale') || navigator?.language?.split('-')[0] || "en");

    const setLanguage = (value: string) => 
    {
        setLocale(value);
        localStorage.setItem('locale', value);
    }

    // const messages = (
    //     locale === "en" && messagesEn ||
    //     locale === "da" && messagesDa ||
    //     messagesEn
    // );

    return (
        <IntlContext.Provider value={{ setLanguage }}>
            <IntlProvider locale={locale} onError={(e) => { }}>
                {props.children}
            </IntlProvider >
        </IntlContext.Provider>
    );
}