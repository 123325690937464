import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useButtonGridStyles = makeStyles((theme: Theme) => 
({
    buttonGrid: {
        margin: -theme.spacing(1),

        "& > .MuiInput-root, & > .MuiButtonBase-root": {
            margin: theme.spacing(1),
        }
    },
}));