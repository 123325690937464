import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme();

theme.overrides = {
    MuiCardContent: {
        root: {
            "&:last-child": {
                paddingBottom: theme.spacing(2)
            }
        }
    }
}

export const Theme = theme;