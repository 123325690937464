import { Container, SearchFieldManaged, SearchFilters, ContainerProps } from 'components';
import React, { useState } from 'react';
import { useSearchBarStyles } from './SearchBar.styles';
import { Collapse, Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import { useSearch } from 'hooks';
import { ButtonGrid } from './ButtonGrid';

export interface SearchBarProps 
{
	filtersOpen?: boolean;

	containerProps?: BoxProps;
}

export const SearchBar: React.FC<SearchBarProps> = (props) =>
{
	const classes = useSearchBarStyles();
	const search = useSearch();
	const [filters, setFilters] = useState(!!props.filtersOpen || !!search.hasFilters || true);

	const onFilters = () => 
	{
		setFilters(!filters);
	}

	return (
		<Box {...props.containerProps} className={clsx(props.containerProps?.className, classes.searchBar__container)}>
			<SearchFieldManaged
				onFilters={onFilters}
				textFieldProps={{
					fullWidth: true,
					InputProps: {
						classes: {
							root: classes.searchBar__searchFieldInputBase,
							notchedOutline: classes.searchBar__searchFieldNotchedOutline,
						}
					}
				}} />
			<Collapse in={filters}>
				<div className={classes.searchBar__filters}>
					<SearchFilters />
				</div>
			</Collapse>
		</Box>
	);
}
