import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useImageItemListStyles = makeStyles((theme: Theme) => 
({
    imageItemList: {

    },
    imageItemList__itemImage: {
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
}));