import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useTextItemListStyles = makeStyles((theme: Theme) => 
({
    textItemList__item: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
        }
    },
}));