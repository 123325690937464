import { SearchFiltersTime, SearchFiltersLanguage, SearchFiltersType, ButtonGrid } from 'components';
import { useSearch } from 'hooks';
import React from 'react';
import { SearchType } from 'enums';
import { Grid } from '@material-ui/core';


export const SearchFilters: React.FC = () =>
{
	const search = useSearch();

	const onChangeTime = (from?: Date, to?: Date) => 
	{
		search.push(oldParams => ({ from, to }));
	};

	const onChangeLanguage = (language?: string) => 
	{
		search.push(oldParams => ({ language }));
	};

	const onChangeType = (type: SearchType) => 
	{
		search.push(oldParams => ({ type }));
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm="auto">
				<SearchFiltersType
					value={search.parameters.type}
					onChange={onChangeType}
				/>
			</Grid>
			{/* <Grid item xs={12} sm="auto">
				<SearchFiltersTime
					from={search.parameters.from}
					to={search.parameters.to}
					onChange={onChangeTime}
				/>
			</Grid>
			{search.parameters.type === SearchType.Text &&
				<Grid item xs={12} sm="auto">
					<SearchFiltersLanguage
						value={search.parameters.language}
						onChange={onChangeLanguage}
					/>
				</Grid>
			} */}
		</Grid>
	);
}
