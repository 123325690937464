
import { Layout, Pages } from 'components';
import { IntlContextProvider, ApolloContextProvider, ThemeContextProvider } from 'contexts';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

export const App: React.FC = () =>
{
	return (
		<BrowserRouter>
			<IntlContextProvider>
				<ApolloContextProvider>
					<ThemeContextProvider>
						<Layout>
							<Pages />
						</Layout>
					</ThemeContextProvider>
				</ApolloContextProvider>
			</IntlContextProvider>
		</BrowserRouter>
	);
}
