import { AppBar, Toolbar, Button, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import BrandIcon from "@material-ui/icons/Home";
import React from 'react';
import { useHeaderStyles } from "./Header.styles";
import { Link } from "react-router-dom";
import { AccountButton } from "components";
import { SettingsButton } from "./SettingsButton";

export const Header: React.FC = () =>
{
	const classes = useHeaderStyles();
	const theme = useTheme();
	const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					<div className={classes.brandContainer}>
						<Link to="/">
							{isExtraSmall &&
								<IconButton edge="start" color="inherit">
									<BrandIcon />
								</IconButton>
							}
							{!isExtraSmall &&
								<Typography variant="h6" noWrap>
									Silly-Moo
          						</Typography>
							}
						</Link>
					</div>
					{/* <SettingsButton 
						iconButtonProps={{
							color: "inherit"
						}}
					/> */}
					{/* <AccountButton 
						iconButtonProps={{
							edge: "end",
							color: "inherit"
						}}
					/> */}
				</Toolbar>
			</AppBar>
			<Toolbar />
		</>
	);
}
