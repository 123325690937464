import { Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ItemSearchResultModel } from 'models';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTextItemShortStyles } from './TextItemShort.styles';

export interface TextItemShortProps 
{
    data: ItemSearchResultModel;
}

export const TextItemShort: React.FC<TextItemShortProps> = (props) =>
{
    const { name, author, description, published, publisher, extract, url } = props.data;

    const classes = useTextItemShortStyles();
    const extractLength = 255;
    const extractTrimmed = extract && extract.length > extractLength && extract.substr(0, extractLength).trimEnd() + "..." || extract;

    return (
        <>
            <div className={classes.itemShort__column1}>
                <div className={classes.itemShort__nameContainer}>
                    <Typography color="primary" className={classes.itemShort__name}>
                        {name}
                    </Typography>
                </div>
                {description &&
                    <div className={classes.itemShort__extractContainer}>
                        <Typography className={classes.itemShort__extract} >
                            {description}
                        </Typography>
                    </div>
                }
                {/* {!description && extractTrimmed &&
                    <div className={classes.itemShort__extractContainer}>
                        <Typography className={classes.itemShort__extract} >
                            {extractTrimmed}
                        </Typography>
                    </div>
                } */}
                {(author || publisher || published) &&
                    <div className={classes.itemShort__metaContainer}>
                        {author &&
                            <Typography>
                                <FormattedMessage
                                    id="authored-by"
                                    defaultMessage="Authored by"
									description=""
                                />
                                <span> </span>
                                <b>{author}</b>
                            </Typography>
                        }
                        {published &&
                            <Typography>
                                <FormattedMessage
                                    id="published-on"
                                    defaultMessage="Published on"
									description=""
                                />
                                <span> </span>
                                <b><FormattedDate year="numeric" month="short" day="numeric" value={published} /></b>
                                {publisher &&
                                    <>
                                        <span> </span>
                                        <FormattedMessage
                                            id="by"
                                            defaultMessage="by"
                                            description=""
                                        />
                                        <span> </span>
                                        <b>{publisher}</b>
                                    </>
                                }
                            </Typography>
                        }
                        {!published && publisher &&
                            <Typography>
                                <FormattedMessage
                                    id="published-by"
                                    defaultMessage="Published by"
									description=""
                                />
                                <span> </span>
                                <b>{publisher}</b>
                            </Typography>
                        }
                        {url &&
                            <OpenInNewIcon className={classes.itemShort__metaIcon} />
                        }
                    </div>
                }
            </div >
        </>
    );
}
