import { List, ListItem } from '@material-ui/core';
import { TextItemShort, ItemLink } from 'components';
import { ItemSearchResultModel } from 'models';
import React, { Fragment } from 'react';
import { useTextItemListStyles } from './TextItemList.styles';

export interface TextItemListProps 
{
	data: ItemSearchResultModel[];
}

export const TextItemList: React.FC<TextItemListProps> = (props) =>
{
	const classes = useTextItemListStyles();

	return (
		<List disablePadding>
			{props.data.map((item, i) => (
				<Fragment key={`${i} ${item.name}`}>
					<ItemLink data={item}>
						<ListItem button className={classes.textItemList__item}>
							<TextItemShort data={item} />
						</ListItem>
					</ItemLink>
				</Fragment>
			))}
		</List>
	);
}