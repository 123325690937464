import { useSearch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchField, SearchFieldProps } from './SearchField';

export interface SearchFieldManaged extends Pick<SearchFieldProps, "disableFilter" | "textFieldProps" | "onFilters"> 
{

}

export const SearchFieldManaged: React.FC<SearchFieldManaged> = (props) =>
{
    const [text, setText] = useState("");
    const location = useLocation();
    const search = useSearch();

    const onChange = (value: string) => 
    {
        setText(value);
    } 

	const onSubmit = (query: string) => 
	{
		search.push(() => ({ query }));
	}

    useEffect(() =>
    {
        if (search.parameters.query === "")
            return;

        setText(search.parameters.query);
        
    }, [location.key])

    return (
        <SearchField 
            {...props} 
            value={text} 
            onChanged={onChange} 
            onSubmit={onSubmit}
        />
    );
}
