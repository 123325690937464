import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Search, Tune } from '@material-ui/icons';
import React, { KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useLocation } from 'react-router-dom';

export interface SearchFieldProps
{
	value?: string;
	textFieldProps?: TextFieldProps;
	disableFilter?: boolean;

	onChanged?: (value: string) => void;
	onFilters?: () => void;
	onSubmit?: (value: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = (props) =>
{
	const t = useIntl();

	const onChange = (e: SyntheticEvent<any>) => 
	{
		if (props.onChanged)
			props.onChanged(e.currentTarget.value);
	}

	const onKey = (e: KeyboardEvent<HTMLInputElement>) => 
	{
		if (e.key !== "Enter")
			return;

		if (!props.value)
			return;

		if (props.onSubmit)
			props.onSubmit(props.value);
	}

	const onSettings = (e: SyntheticEvent<any>) => 
	{
		if (props.onFilters)
			props.onFilters();
	}

	const onSubmit = (e: SyntheticEvent<any>) => 
	{
		if (!props.value)
			return;

		if (props.onSubmit)
			props.onSubmit(props.value);
	}

	return (
		<TextField
			{...props.textFieldProps}
			variant="outlined"
			onChange={onChange}
			onSubmit={onSubmit}
			value={props.value}
			placeholder={t.formatMessage({ 
				id: "search-ellipsis",
				defaultMessage: "Search...",
				description: ""
			})}
			inputProps={{
				onKeyPress: onKey
			}}
			InputProps={{
				...props.textFieldProps?.InputProps,
				startAdornment: (
					!props.disableFilter && <>
						{props.onFilters &&
							<InputAdornment position="start">
								<IconButton edge="start" onClick={onSettings}>
									<Tune />
								</IconButton>
							</InputAdornment>
						}
					</>
				),
				endAdornment: (
					<>
						<InputAdornment position="end">
							<IconButton edge="end" onClick={onSubmit}>
								<Search />
							</IconButton>
						</InputAdornment>
					</>
				)
			}}
		/>
	);
}
