import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useSearchBarStyles = makeStyles((theme: Theme) => 
({
    searchBar__container: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        padding: theme.spacing(1),
    },
    searchBar__searchFieldInputBase: {
        backgroundColor: "#fff",
    },
    searchBar__searchFieldNotchedOutline: {
        borderColor: "white",
    },
    searchBar__filters: {
        paddingTop: theme.spacing(1),
    },
}));