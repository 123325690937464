import { Typography, Box, Container } from '@material-ui/core';
import React from 'react';
import { useStyles } from './FrontPage.styles';
import { SearchBar, SearchFieldManaged } from 'components';

export const FrontPage: React.FC = () =>
{
	const classes = useStyles();

	return (
		<Box className={classes.frontPage}>
			<Container className={classes.frontPage__container}>
				<Box className={classes.frontPage__brandContainer}>
					<Typography variant="h4">Silly-Moo</Typography>
				</Box>
				<SearchBar
					filtersOpen
					containerProps={{
						className: classes.frontPage__searchContainer
					}}
				/>
			</Container>
		</Box>
	);
}
