import { Box, CircularProgress } from '@material-ui/core';
import { Container, Delay } from 'components';
import React from 'react';

export const Loader: React.FC = (props) =>
{
    return (
        <Container>
            <Box textAlign="center">
                <Delay time={250}>
                    <CircularProgress />
                </Delay>
            </Box>
        </Container>
    );
}
