import React, { FC, useEffect, useState } from 'react';

export interface DelayProps
{
    time?: number;
}

export const Delay: FC<DelayProps> = (props) =>
{
    const [show, setShow] = useState(false);

    useEffect(() => 
    {
        const timer = setTimeout(() => setShow(true), props.time || 2000);

        return () => clearTimeout(timer);
    }, [])

    if (!show)
        return null;

    return <>{props.children}</>;
}