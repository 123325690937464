import React, { Fragment, FC } from 'react';
import { ItemDetailsModel } from 'models';
import { Typography } from '@material-ui/core';
import { Container } from 'components';

export interface ItemDetailsProps 
{
	data: ItemDetailsModel;
}

export const ItemDetails: FC<ItemDetailsProps> = (props) =>
{
	return (
		<Container>
			<Typography variant="h4">{props.data.name}</Typography>
		</Container>
	);
}
