import { ItemSearchResultModel } from "models";
import { useSearch } from "hooks";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

export interface ItemLinkProps
{
    data: ItemSearchResultModel;
}

export const ItemLink: React.FC<ItemLinkProps> = (props) =>
{
    const search = useSearch();

    if (props.data.url)
    {
        return (
            <a href={props.data.url} target="_blank">
                {props.children}
            </a>
        );
    }

    return (
        <a href={axios.defaults.baseURL + `item/file/${props.data.providerId}/${props.data.id}`} target="_blank">
            {props.children}
        </a>
    );

    const url = search.createUrl(() => ({ itemId: props.data.id }));

    return (
        <Link to={url}>
            {props.children}
        </Link>
    );
}