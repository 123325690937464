import { Footer, Header } from 'components';
import React from 'react';

export const Layout: React.FC = (props) =>
{
	return (
		<>
			<Header />
			{props.children}
			<Footer />
		</>
	);
}
