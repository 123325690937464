import axios from "axios";
import { ItemSearchResultModel, PaginationModel } from "models";
import { SearchType } from "enums";

export const useApi_searchItems = (
    query: string,
    publishedFrom?: Date,
    publishedTo?: Date,
    language?: string,
    type?: SearchType,
    page?: number,
) =>
{
    const get = () => 
    {
        let url = `item/search?query=${query}`;

        if (publishedFrom)
            url += `&publishedFrom=${publishedFrom.toISOString()}`;
    
        if (publishedTo)
            url += `&publishedTo=${publishedTo?.toISOString()}`;
    
        if (language)
            url += `&language=${language}`;
    
        if (type)
            url += `&type=${type === SearchType.Text && 0 || type === SearchType.Image && 1 || 0}`;
    
        if (page)
            url += `&page=${page}`;
    
        return axios.get<PaginationModel<ItemSearchResultModel>>(url);
    }
    
    return get;
}