import { GridList, GridListTile, Box } from '@material-ui/core';
import { ItemLink } from 'components';
import { ItemSearchResultModel } from 'models';
import React from 'react';
import { useImageItemListStyles } from './ImageItemList.styles';

export interface ImageItemListProps 
{
	cellHeight?: number;
	cols?: number;
	data: ItemSearchResultModel[];
}

export const ImageItemList: React.FC<ImageItemListProps> = (props) =>
{
	const classes = useImageItemListStyles();

	return (
		<GridList cellHeight={props.cellHeight || 160} cols={props.cols || 4}>
			{props.data.map((item, i) => (
				<GridListTile key={`${i} ${item.name}`} cols={1}>
					<ItemLink data={item}>
						<div
							className={classes.imageItemList__itemImage}
							style={{ backgroundImage: `url('${item.thumbnailUrl}')` }}
						/>
					</ItemLink>
				</GridListTile>
			))}
		</GridList>
	);
}
