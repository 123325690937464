import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => 
({
    frontPage: {
        paddingTop: 96,
    },
    frontPage__container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    frontPage__brandContainer: {
        marginBottom: 16,
    },
    frontPage__searchContainer: {
        width: "100%",
        maxWidth: 600,
        borderRadius: theme.shape.borderRadius,
    },
}));