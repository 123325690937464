import { FrontPage, SearchPage } from 'components';
import { useSearch } from 'hooks';
import React from 'react';

export const Pages: React.FC = () =>
{
    const search = useSearch();

    if (search.parameters.query.length > 0)
    {
        return (
            <SearchPage />
        );
    }

    return (
        <FrontPage />
    )
}
