import { Box, Typography } from "@material-ui/core";
import { Container, TextItemList, Err } from 'components';
import { SearchType } from "enums";
import { useSearch, useWindowSize, useApi } from "hooks";
import { ItemSearchResultModel, PaginationModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ImageItemList } from "./ImageItemList";
import { Loader } from "./Loader";

export const ResultPage: React.FC = () =>
{
	const t = useIntl();
	const location = useLocation();
	const windowSize = useWindowSize();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [items, setItems] = useState<PaginationModel<ItemSearchResultModel>>();
	const { parameters, push } = useSearch();
	const searchItems = useApi.searchItems(parameters.query, parameters.from, parameters.to, parameters.language, parameters.type, parameters.page);

	const onFetch = () => 
	{
		if (!searchItems)
			return;

		setLoading(true);
		setError(undefined);
		searchItems()
			.then((res) =>
			{
				setItems(res.data);
			})
			.catch((err) =>
			{
				setError(err);
			})
			.finally(() => 
			{
				setLoading(false);
			});
	};

	const onChangePage = (event: any, page: number) => 
	{
		push(() => ({ page }));
	}

	useEffect(onFetch, [location.key]);

	if (loading)
	{
		return <Loader />;
	}


	if (error)
	{
		return <Err data={error} />;
	}

	if (!items || items.data.length === 0)
	{
		return (
			<Container>
				<Typography>
					<FormattedMessage 
						id="no-query-match"
						defaultMessage={`There is no match for "{query}".`}
						values={{
							query: parameters?.query
						}}
					/>
				</Typography>
			</Container>
		);
	}

	if (parameters.type === SearchType.Image)
	{
		const cellHeight = windowSize.width && windowSize.width < 300 && 160 || 160;
		const cols = Math.floor(windowSize.width && windowSize.width / 200 || 6)

		return (
			<Box p={0.5}>
				<ImageItemList
					cellHeight={cellHeight}
					cols={cols}
					data={items.data}
				/>
			</Box>
		);
	}

	return (
		<>
			<TextItemList
				data={items.data}
			/>
			{/* <TablePagination
				component="div"
				count={items.total}
				rowsPerPage={20}
				rowsPerPageOptions={[20]}
				page={items.index}
				onChangePage={onChangePage}
			/> */}
		</>
	);
}
