import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ErrProps 
{
	data: any;
}

export const Err: FC<ErrProps> = (props) =>
{
	const { data: error } = props;
	const t = useIntl();

	if (!(error?.data?.error?.message))
	{
		return (
			<Box>
				<FormattedMessage
					id="unknown-error"
					defaultMessage="Unknown error."
					description=""
				/>
			</Box>
		);
	}

	return (
		<Box>
			{error.data.error.message}
		</Box>
	);
}
