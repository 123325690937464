import { MenuItem, Select, Button, Menu, ListItemIcon, ListItemText } from '@material-ui/core';
import { SearchType } from 'enums';
import React from 'react';
import { useIntl } from 'react-intl';
import ImageIcon from '@material-ui/icons/Image';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export interface SearchFiltersTypeProps
{
	value: SearchType;
	onChange?: (value: SearchType) => void;
}

export const SearchFiltersType: React.FC<SearchFiltersTypeProps> = (props) =>
{
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
	const t = useIntl();

	const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
		setAnchorEl(event.currentTarget);
	};

	const onClickMenu = (value: SearchType) => (event: React.MouseEvent<HTMLLIElement>) =>
	{
		setAnchorEl(undefined);

		if (props.onChange)
			props.onChange(value);
	};

	const onCloseMenu = () =>
	{
		setAnchorEl(undefined);
	};

	const textTexts = t.formatMessage({ 
		id: "filter-type-text",
		defaultMessage: "Text",
		description: ""
	});

	const textImages = t.formatMessage({ 
		id: "filter-type-images",
		defaultMessage: "Images",
		description: ""
	});

	return (
		<>
			<Button
				variant="text" 
				onClick={onClickButton}
				endIcon={<ArrowDropDownIcon />}
				startIcon={
					<>
						{props.value === SearchType.Text && <DescriptionIcon />}
						{props.value === SearchType.Image && <ImageIcon />}
					</>
				}
			>
				{props.value === SearchType.Text && textTexts}
				{props.value === SearchType.Image && textImages}
			</Button>

			<Menu
				getContentAnchorEl={null}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={onCloseMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem value={SearchType.Text} onClick={onClickMenu(SearchType.Text)}>
					<ListItemIcon>
						<DescriptionIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary={textTexts} />
				</MenuItem>
				<MenuItem value={SearchType.Image} onClick={onClickMenu(SearchType.Image)}>
					<ListItemIcon>
						<ImageIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary={textImages} />
				</MenuItem>
			</Menu>
		</>
	);
}
