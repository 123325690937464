import { SearchBar, ItemPage, ResultPage } from 'components';
import React from 'react';
import { Route, Switch, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useSearch } from 'hooks';

export const SearchPage: React.FC = () =>
{
	const match = useRouteMatch();
	const search = useSearch();

	return (
		<>
			<SearchBar />
			{search.parameters.itemId &&
				<ItemPage />
			}
			{!search.parameters.itemId &&
				<ResultPage />
			}
		</>
	);
}
