import React, { createContext, FC, useState } from "react";
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = apiUrl;

export interface ApolloContextProps
{
}

export const ApolloContextProvider: FC<ApolloContextProps> = (props) =>
{
    const cache = new InMemoryCache();
    const link = new HttpLink({
        uri: apiUrl,
    });

    const client = new ApolloClient({
        cache: cache,
        link: link,
        queryDeduplication: false,
        defaultOptions:
        {
            watchQuery:
            {
                fetchPolicy: 'cache-and-network',
            },
        },
    });

    return (
        <ApolloProvider client={client} >
            {props.children}
        </ApolloProvider >
    );
}