import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { SearchType } from "enums";

export type SearchCreateUrl = (func: (currentParameters: SearchParameters) => Partial<SearchParameters>) => string;

export type SearchPush = (func: (currentParameters: SearchParameters) => Partial<SearchParameters>) => void;

export interface Search
{
    parameters: SearchParameters;
    push: SearchPush;
    createUrl: SearchCreateUrl;
    hasFilters?: boolean;
}

export interface SearchParameters
{
    query: string;
    page: number;
    from?: Date;
    to?: Date;
    type: SearchType;
    language?: string;
    itemId?: string;
}

export const useSearch = (): Search => 
{
    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);

    const query = params.query as string | undefined || "";
    const page = typeof params.page === "string" && parseFloat(params.page) || 0;
    const from = typeof params.from === "string" && new Date(params.from) || undefined;
    const to = typeof params.to === "string" && new Date(params.to) || undefined;
    const type = params.type as SearchType | undefined || SearchType.Text;
    const language = params.language as string | undefined || "";
    const itemId = params.itemId as string | undefined || "";

    const parameters: SearchParameters = {
        query,
        page,
        from,
        to,
        type,
        language,
        itemId,
    };

    const hasFilters = !!from || !!to || !!language;

    const createUrl: SearchCreateUrl = (func) => 
    {
        const partialParameters = func(parameters);
        const newParameters = { ...parameters, ...partialParameters };

        if (!partialParameters.itemId)
            newParameters.itemId = undefined;

        if (newParameters.from && newParameters.to && newParameters.from > newParameters.to)
        {
            const temp = newParameters.from;
            newParameters.from = newParameters.to;
            newParameters.to = temp;
        }

        let url = `/item/search?query=${newParameters.query}`;

        if (newParameters.page !== 0)
            url += `&page=${newParameters.page}`

        if (newParameters.from !== undefined)
            url += `&from=${newParameters.from.toISOString()}`

        if (newParameters.to !== undefined)
            url += `&to=${newParameters.to.toISOString()}`

        if (newParameters.itemId !== undefined)
            url += `&itemId=${newParameters.itemId}`

        if (newParameters.type !== undefined)
            url += `&type=${newParameters.type}`

        if (newParameters.language !== undefined)
            url += `&language=${newParameters.language}`

        return url;
    }

    const push: SearchPush = (func) => 
    {
        const url = createUrl(func);

        history.push(url);
    }

    return {
        parameters,
        push,
        createUrl,
        hasFilters,
    };
}