import React from 'react';
import { useContainerStyles } from './Container.styles';
import clsx from 'clsx';

export interface ContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
{
	
}

export const Container: React.FC<ContainerProps> = (props) =>
{
    const classes = useContainerStyles();

	return (
		<div {...props} className={clsx(props.className, classes.container)}>
			{props.children}
		</div>
	);
}
