import React, { createContext, FC, useState } from "react";
import { ThemeProvider } from '@material-ui/core';
import { Theme } from 'style';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export interface ThemeContextProps
{
}

export interface ThemeContextState
{
}

export const ThemeContextProvider: FC<ThemeContextProps> = (props) =>
{

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={Theme}>
                {props.children}
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}